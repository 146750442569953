import keymirror from 'keymirror';

export default keymirror({
  DELETE_CATEGORY_LOADING: null,
  DELETE_CATEGORY_SUCCESS: null,
  DELETE_CATEGORY_ERROR: null,
  EVAL_SESSION_LOADING: null,
  EVAL_SESSION_SUCCESS: null,
  EVAL_SESSION_ERROR: null,
  TEST_SESSION_LOADING: null,
  TEST_SESSION_SUCCESS: null,
  TEST_SESSION_ERROR: null,
  OBSERVATION_SESSION_LOADING: null,
  OBSERVATION_SESSION_SUCCESS: null,
  OBSERVATION_SESSION_ERROR: null,
  OBSERVATION_LOADING: null,
  OBSERVATION_SUCCESS: null,
  OBSERVATION_ERROR: null,
  PENDING_CORRECTIVE_ACTIONS_LIST_LOADING: null,
  PENDING_CORRECTIVE_ACTIONS_LIST_SUCCESS: null,
  PENDING_CORRECTIVE_ACTIONS_LIST_ERROR: null,
  USERS_UNARCHIVE_PROCESSING: null,
  USERS_UNARCHIVE_SUCCESS: null,
  USERS_UNARCHIVE_ERROR: null,
  USER_HISTORY_LOADING: null,
  USER_HISTORY_SUCCESS: null,
  USER_HISTORY_ERROR: null,
  COMPLETE_PENDING_CORRECTIVE_ACTION_LOADING: null,
  COMPLETE_PENDING_CORRECTIVE_ACTION_SUCCESS: null,
  COMPLETE_PENDING_CORRECTIVE_ACTION_ERROR: null,
  USER_FETCH_SUCCESS: null,
  USERS_LIST_LOADING: null,
  USERS_LIST_SUCCESS: null,
  USERS_LIST_ERROR: null,
  USER_SECURITY_FIELDS_FETCH_SUCCESS: null,
  USER_SECURITY_FIELDS_ERROR: null,
  USERS_ARCHIVE_PROCESSING: null,
  USERS_ARCHIVE_SUCCESS: null,
  USERS_ARCHIVE_ERROR: null,
  USERS_MERGE_PROCESSING: null,
  USERS_MERGE_SUCCESS: null,
  USERS_MERGE_ERROR: null,
  SUPERVISED_GROUPS_OPTIONS_LOADING: null,
  SUPERVISED_GROUPS_OPTIONS_SUCCESS: null,
  SUPERVISED_GROUPS_OPTIONS_ERROR: null,
  GROUP_HISTORY_LOADING: null,
  GROUP_HISTORY_SUCCESS: null,
  GROUP_HISTORY_ERROR: null,
  GROUPS_LIST_LOADING: null,
  GROUPS_LIST_SUCCESS: null,
  GROUPS_LIST_ERROR: null,
  GROUP_MEMBERS_LOADING: null,
  GROUP_MEMBERS_SUCCESS: null,
  GROUP_MEMBERS_ERROR: null,
  PATCH_GROUP_MEMBERS_LOADING: null,
  PATCH_GROUP_MEMBERS_SUCCESS: null,
  PATCH_GROUP_MEMBERS_ERROR: null,
  RECEIVE_POWERS: null,
  USER_TRANSCRIPTS_LOADING: null,
  USER_TRANSCRIPTS_SUCCESS: null,
  USER_TRANSCRIPTS_ERROR: null,
  CLEAR_ERROR: null,
  ADD_USER_LOADING: null,
  ADD_USER_SUCCESS: null,
  ADD_USER_ERROR: null,
  ADD_CATEGORY_LOADING: null,
  ADD_CATEGORY_SUCCESS: null,
  ADD_CATEGORY_ERROR: null,
  SUPERVISING_GROUPS_OPTIONS_LOADING: null,
  SUPERVISING_GROUPS_OPTIONS_SUCCESS: null,
  SUPERVISING_GROUPS_OPTIONS_ERROR: null,
  LISTING_OPTIONS_LOADING: null,
  LISTING_OPTIONS_SUCCESS: null,
  LISTING_OPTIONS_SUCCESS_SUPPLEMENT: null,
  LISTING_OPTIONS_ERROR: null,
  LISTING_OPTIONS_SELECT_ALL: null,
  LISTING_OPTIONS_UNSELECT_ALL: null,
  LISTING_OPTIONS_SELECT_ITEM: null,
  LISTING_OPTIONS_SELECT_SINGLE_ITEM: null,
  LISTING_OPTIONS_UNSELECT_ITEM: null,
  LISTING_OPTIONS_SET_SELECTED_ITEMS: null,
  LISTING_OPTIONS_SELECT_PARENT_ITEM: null,
  LISTING_OPTIONS_UNSELECT_PARENT_ITEM: null,
  LISTING_OPTIONS_SELECT_CHILD_ITEM: null,
  LISTING_OPTIONS_UNSELECT_CHILD_ITEM: null,
  SHOW_SNACKBAR: null,
  CLOSE_SNACKBAR: null,
  PENDING_USER_FETCH_SUCCESS: null,
  PENDING_USERS_LIST_LOADING: null,
  PENDING_USERS_LIST_SUCCESS: null,
  PENDING_USERS_LIST_ERROR: null,
  PENDING_USER_SECURITY_FIELDS_FETCH_SUCCESS: null,
  PENDING_USER_SECURITY_FIELDS_ERROR: null,
  PATCH_CATEGORY_LOADING: null,
  PATCH_CATEGORY_SUCCESS: null,
  PATCH_CATEGORY_ERROR: null,
  PATCH_COURSE_LOADING: null,
  PATCH_COURSE_SUCCESS: null,
  PATCH_COURSE_ERROR: null,
  PATCH_CURRENT_USER_LOADING: null,
  PATCH_CURRENT_USER_SUCCESS: null,
  PATCH_CURRENT_USER_ERROR: null,
  PATCH_USER_LOADING: null,
  PATCH_USER_SUCCESS: null,
  PATCH_USER_ERROR: null,
  PATCH_PARENT_COURSE_LOADING: null,
  PATCH_PARENT_COURSE_SUCCESS: null,
  PATCH_PARENT_COURSE_ERROR: null,
  PATCH_REPORT_RUNNER_LOADING: null,
  PATCH_REPORT_RUNNER_SUCCESS: null,
  PATCH_REPORT_RUNNER_ERROR: null,
  PATCH_SYSTEM_GROUP_LOADING: null,
  PATCH_SYSTEM_GROUP_SUCCESS: null,
  PATCH_SYSTEM_GROUP_ERROR: null,
  APPROVE_USER_PROCESSING: null,
  APPROVE_USER_SUCCESS: null,
  APPROVE_USER_ERROR: null,
  DELETE_USER_PROCESSING: null,
  DELETE_USER_SUCCESS: null,
  DELETE_USER_ERROR: null,
  COURSE_FETCH_SUCCESS: null,
  ADD_COURSE_LOADING: null,
  ADD_COURSE_SUCCESS: null,
  ADD_COURSE_ERROR: null,
  ADD_SCB_COURSE_LOADING: null,
  ADD_SCB_COURSE_SUCCESS: null,
  ADD_SCB_COURSE_ERROR: null,
  COURSE_HISTORY_LOADING: null,
  COURSE_HISTORY_SUCCESS: null,
  COURSE_HISTORY_ERROR: null,
  COURSES_ACTIVITIES_LOADING: null,
  COURSES_ACTIVITIES_SUCCESS: null,
  COURSES_ACTIVITIES_ERROR: null,
  GET_CATEGORY_LOADING: null,
  GET_CATEGORY_SUCCESS: null,
  GET_CATEGORY_ERROR: null,
  USER_RESET_PASSWORD_PROCESSING: null,
  USER_RESET_PASSWORD_SUCCESS: null,
  USER_RESET_PASSWORD_ERROR: null,
  REDEEM_TOKEN_LOADING: null,
  REDEEM_TOKEN_SUCCESS: null,
  REDEEM_TOKEN_ERROR: null,
  CATEGORY_FETCH_SUCCESS: null,
  CATEGORIES_LIST_LOADING: null,
  CATEGORIES_LIST_SUCCESS: null,
  CATEGORIES_LIST_ERROR: null,
  CATEGORY_HISTORY_LOADING: null,
  CATEGORY_HISTORY_SUCCESS: null,
  CATEGORY_HISTORY_ERROR: null,
  PARENT_COURSES_LIST_LOADING: null,
  PARENT_COURSES_LIST_SUCCESS: null,
  PARENT_COURSES_LIST_ERROR: null,
  PARENT_COURSE_FETCH_SUCCESS: null,
  GET_LEARNING_PLAN_SUCCESS: null,
  LEARNING_PLANS_LIST_SUCCESS: null,
  LEARNING_PLANS_LIST_LOADING: null,
  LEARNING_PLANS_LIST_ERROR: null,
  ADD_LEARNING_PLAN_LOADING: null,
  ADD_LEARNING_PLAN_SUCCESS: null,
  ADD_LEARNING_PLAN_ERROR: null,
  PATCH_LEARNING_PLAN_SUCCESS: null,
  PATCH_LEARNING_PLAN_LOADING: null,
  PATCH_LEARNING_PLAN_ERROR: null,
  DELETE_LEARNING_PLAN_SUCCESS: null,
  LEARNING_PLAN_SUBSCRIPTIONS_LIST_SUCCESS: null,
  LEARNING_PLAN_SUBSCRIPTIONS_LIST_LOADING: null,
  LEARNING_PLAN_SUBSCRIPTIONS_LIST_ERROR: null,
  LEARNING_PLAN_SUBSCRIPTIONS_OPTIONS_LIST_SUCCESS: null,
  LEARNING_PLAN_SUBSCRIPTIONS_OPTIONS_LIST_LOADING: null,
  LEARNING_PLAN_SUBSCRIPTIONS_OPTIONS_LIST_ERROR: null,
  PATCH_LEARNING_PLAN_SUBSCRIPTIONS_SUCCESS: null,
  PATCH_LEARNING_PLAN_SUBSCRIPTIONS_LOADING: null,
  PATCH_LEARNING_PLAN_SUBSCRIPTIONS_ERROR: null,
  DELETE_LEARNING_PLAN_SUBSCRIPTIONS_SUCCESS: null,
  GET_LEARNING_PLAN_SUBSCRIPTIONS_SUCCESS: null,
  PATCH_USER_LEARNING_PLAN_SUBSCRIPTIONS_LOADING: null,
  PATCH_USER_LEARNING_PLAN_SUBSCRIPTIONS_SUCCESS: null,
  PATCH_USER_LEARNING_PLAN_SUBSCRIPTIONS_ERROR: null,
  PATCH_GROUP_LEARNING_PLAN_SUBSCRIPTIONS_LOADING: null,
  PATCH_GROUP_LEARNING_PLAN_SUBSCRIPTIONS_SUCCESS: null,
  PATCH_GROUP_LEARNING_PLAN_SUBSCRIPTIONS_ERROR: null,
  LEARNING_PLAN_HISTORY_LOADING: null,
  LEARNING_PLAN_HISTORY_SUCCESS: null,
  LEARNING_PLAN_HISTORY_ERROR: null,
  SHOW_SWITCH_BAR: null,
  HIDE_SWITCH_BAR: null,
  REPORT_RESULT_LOADING: null,
  REPORT_RESULT_SUCCESS: null,
  REPORT_RESULT_ERROR: null,
  GET_REPORTS_LOADING: null,
  GET_REPORTS_SUCCESS: null,
  GET_REPORTS_ERROR: null,
  REPORT_RUNNER_LOADING: null,
  REPORT_RUNNER_SUCCESS: null,
  REPORT_RUNNER_ERROR: null,
  CONFIG_LOAD_ALL_SUCCESS: null,
  CONFIG_LOAD_ONE_LOADING: null,
  CONFIG_LOAD_ONE_SUCCESS: null,
  CONFIG_LOAD_ONE_ERROR: null,
  FILES_DELETE_PROCESSING: null,
  FILES_DELETE_SUCCESS: null,
  PATCH_COURSES_PROCESSING: null,
  PATCH_COURSES_SUCCESS: null,
  PATCH_COURSES_ERROR: null,
  SUBSCRIPTION_FETCH_SUCCESS: null,
  ADD_CHECKPOINT_LOADING: null,
  ADD_CHECKPOINT_SUCCESS: null,
  ADD_CHECKPOINT_ERROR: null,
  CREATE_JWT_TOKEN_LOADING: null,
  CREATE_JWT_TOKEN_SUCCESS: null,
  CREATE_JWT_TOKEN_ERROR: null,
  ADD_SYSTEM_GROUP_LOADING: null,
  ADD_SYSTEM_GROUP_SUCCESS: null,
  ADD_SYSTEM_GROUP_ERROR: null,
  GROUP_FETCH_SUCCESS: null,
  DELETE_SYSTEM_GROUP_LOADING: null,
  DELETE_SYSTEM_GROUP_SUCCESS: null,
  DELETE_SYSTEM_GROUP_ERROR: null,
  GROUP_ADMINS_LIST_LOADING: null,
  GROUP_ADMINS_LIST_SUCCESS: null,
  GROUP_ADMINS_LIST_ERROR: null,
  GET_CHECKPOINT_LOADING: null,
  GET_CHECKPOINT_SUCCESS: null,
  GET_CHECKPOINT_ERROR: null,
  PATCH_GROUP_ADMINS_LOADING: null,
  PATCH_GROUP_ADMINS_SUCCESS: null,
  PATCH_GROUP_ADMINS_ERROR: null,
  INSTRUCTION_LOADING: null,
  SERVER_EXCEPTION: null,
});
